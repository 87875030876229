
<template>
  <el-drawer ref="drawerRef" v-model="dialogFormVisible" :title="title" :size="size" :before-close="handleClose" :direction="direction" custom-class="demo-drawer">
    <el-form ref="formRef" label-width="80px" :model="state.form" :rules="state.rules">
      <el-form-item label="门票选择" prop="ticketCode">
        <el-select class="w-percent-100 m-2" v-model="state.ticketSelect" placeholder="请选择要关联的门票" @change="handleChangeSelect">
          <el-option v-for="item in state.list" :key="item.ticketCode" :label="item.value" :value="item.ticketCode" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="门票名称" prop="ticketName">
        <el-autocomplete
          v-model="state.form.ticketName"
          :fetch-suggestions="querySearch"
          placeholder="请选择要关联的门票名称"
          @select="handleSelect"
        ></el-autocomplete>
      </el-form-item> -->
      <el-form-item label="门票名称" prop="ticketName">
        <el-input v-model="state.form.ticketName" placeholder="请输入要添加的门票名称" />
      </el-form-item>
    </el-form>
    <template v-if="state.ticketDetailList.length">
      <div class="mb-10">
        <span style="margin-left: 5px">{{ categoryName }}</span>
        <span>关联的名称列表如下：</span>
      </div>
      <template v-for="(item, index) in state.ticketDetailList" :key="index">
        <el-tag class="mr-10 mb-10" type="success" closable @close="handleTicketShowNameClose(item, index)" @click="handleEditTicketShowName(item)">
          {{ item.ticketShowName }}
        </el-tag>
      </template>
    </template>
    <div class="demo-drawer__footer">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="handleSave">添加</el-button>
    </div>
    <editTicketsGatherConfigPopup ref="editItem"></editTicketsGatherConfigPopup>
    <el-dialog v-model="dialogVisible1" top="25vh" width="15%" append-to-body title="确认要删除吗?">
      <div class="d-flex align-center">
        <el-button class="flex-1 mr-10" type="primary" size="medium" @click="dialogVisible1 = false">
          取消
        </el-button>
        <el-button class="flex-1 ml-10" size="medium" type="primary" @click="handleConfirmDelete">
          确认
        </el-button>
      </div>
    </el-dialog>
  </el-drawer>
</template>
<script>
export default { name: 'editConfigDrawer1' }
</script>
<script setup>
import { getCurrentInstance, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useComponent } from './index'
import Bus from '@/utils/bus'
import {
  addTicketCategoryRef,
  getTicketList,
  delTicketCategoryRef,
} from '@/api/exhibitionManage'
const props = defineProps({
  title: {
    type: String,
    default: '新增门票集合页里面的门票配置',
  },
  categoryName: {
    type: String,
    default: '',
  },
  meetingCode: {
    type: String,
    default: '',
  },
})
const { proxy } = getCurrentInstance()
const emit = defineEmits(['noticeRefresh'])
const dialogFormVisible = ref(false) // 打开或者关闭抽屉
const formRef = ref(false) // 表单数据
const editItem = ref(null) // 编辑集合配置
const direction = ref('ltr') // 抽屉弹出的方向
const dialogVisible1 = ref(false) // 抽屉弹出的方向
const size = ref('35%') //抽屉大小
// 引入组件
const { editTicketsGatherConfigPopup } = useComponent()
const deleteObj = reactive({
  id: 0,
  ticketCategoryId: 0,
  ticketCode: '',
  ticketName: '',
})
let delIndex = ref(0)
const state = reactive({
  ticketSelect: '',
  formInline: { inputName: '12132' },
  list: [],
  pageNum: 1,
  pageSize: 999999,
  form: {
    ticketCategoryId: 0,
    ticketCode: '',
    ticketName: '',
  },
  ticketDetailList: [],
  rules: {
    ticketName: [
      {
        required: true,
        trigger: 'blur',
        message: '请输入要添加的门票名称',
      },
    ],
    ticketCode: [
      {
        required: true,
        trigger: 'blur',
        message: '请选择要关联的门票',
      },
    ],
  },
})
const handleSelect = (item) => {
  state.form.ticketCode = item.ticketCode
  state.form.ticketName = item.value
}
// 数据获取
const getData = async () => {
  let params = {
    pageNum: state.pageNum,
    pageSize: state.pageSize,
    ticketName: state.form.ticketName,
    meetingCode: props.meetingCode,
    meetingType: 'EXHIBITION'
  }
  let { data } = await getTicketList(params)
  state.list = data.list.length
    ? data.list.map((item) => ({
        ticketCode: item.ticketCode,
        value: item.ticketName,
      }))
    : []
}
// 下拉事件
const handleChangeSelect = (e) => {
  state.form.ticketCode = e
  let item = state.list.find((item) => item.ticketCode == e)
  state.ticketSelect = item.value || ''
}
// 查询搜索
// const querySearch = (searchName, cb) => {
//   var list = [...state.list]
//   var results = searchName ? list.filter(createStateFilter(searchName)) : list
//   cb(results)
// }
// const createStateFilter = (searchName) => {
//   return (state) => {
//     return state.value.toLowerCase().indexOf(searchName.toLowerCase()) > -1
//   }
// }
// 打开
const handleOpen = (row = {}) => {
  console.log('handleOpen', row)
  let obj = {
    ticketCategoryId: row.ticketCategoryId,
    ticketCode: row.ticketCode,
  }
  state.ticketDetailList = row.ticketDetailList
  state.form = Object.assign(state.form, obj)
  dialogFormVisible.value = true

  getData()
}
// 关闭
const handleClose = () => {
  formRef.value.resetFields()
  state.ticketSelect = ''
  dialogFormVisible.value = false
}
// 编辑
const handleEditTicketShowName = (item) => {
  let row = {
    id: item.id,
    ticketCategoryId: state.form.ticketCategoryId,
    ticketCode: item.ticketCode,
    ticketName: item.ticketShowName,
  }
  let list = state.list.length ? state.list : []
  console.log('list', list)
  editItem.value.handleOpen(row, list)
}
// 删除
const handleConfirmDelete = async () => {
  try {
    let resp = await delTicketCategoryRef(deleteObj)
    let index = Number(delIndex.value)
    state.ticketDetailList.splice(index, 1)
    proxy.$baseMessage(`删除成功`, 'success', 'vab-hey-message-success')
  } catch (error) {
    proxy.$baseMessage(`删除失败`, 'error', 'vab-hey-message-error')
  } finally {
    dialogVisible1.value = false
    emit('noticeRefresh')
  }
}
// 打开删除弹框
const handleTicketShowNameClose = async (item, index) => {
  dialogVisible1.value = true
  delIndex.value = index
  deleteObj.id = item.id || ''
  deleteObj.ticketCategoryId = state.form.ticketCategoryId || ''
  deleteObj.ticketCode = item.ticketCode || ''
  deleteObj.ticketName = item.ticketShowName || ''
}
// 提交
const handleSave = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      let data = await addTicketCategoryRef(state.form)
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      emit('noticeRefresh')
      handleClose()
    }
  })
}
onMounted(() => {
  Bus.$on('closeDrawer1', () => {
    handleClose()
  })
})
onUnmounted(() => {
  Bus.$off('closeDrawer1', () => {})
})
defineExpose({
  handleClose,
  handleOpen,
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}

.demo-drawer__content {
  height: 85vh;
  overflow: auto;
}
.demo-drawer__footer {
  position: fixed;
  left: 20px;
  bottom: 10px;
}
</style>
