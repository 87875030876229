// 发布订阅者模式也称为观察者模式
// 订阅发布模式在开发中用到的设计模式中还是比较常见的
class Observer {
  constructor() {
    this.message = {} //消息队列
  }
  $on(type, callback) {
    // 判断是否有订阅类型
    console.log('type===', type)
    if (!this.message[type]) {
      console.log(this.message[type])
      // 初始化没有这个属性，我就添加一个
      this.message[type] = [callback]
    } else {
      this.message[type].push(callback)
    }
  }
  isValue(arg) {
    let flag =
      Object.prototype.toString.call(arg) == '[object Array]' ||
      Object.prototype.toString.call(arg) == '[object Object]'
    if (flag) {
      if (arg instanceof Array) {
        return arg.length ? arg : null
      }
      return Object.keys(arg).length ? arg : null
    } else {
      return arg ? arg : null
    }
  }
  $emit(type, arg = null) {
    console.log('emit', arg)
    let args = this.isValue(arg)
    // 判断是否有订阅类型
    if (!this.message[type]) return
    this.message[type].forEach((callback) => {
      callback(args)
    })
  }
  $off(type, callback) {
    // 判断是否有订阅类型
    if (!this.message[type]) return
    if (!callback) return
    // 如果有callback这个类型就删除掉
    this.message[type] = this.message[type].filter((item) => item != callback)
  }
}
export default new Observer()
